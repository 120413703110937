<template>
  <layout-admin>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <div class="content">
          <div class="container-content">
            <div class="header-content">
              <h2>
                <img src="https://sippp.bappenas.my.id/assets/logo/logo-sippp2.png" style="width:250px;margin:10px;"
                  alt="">
              </h2>
            </div>
            <div class="body-content">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-2 mb-4">
                      <div class="card-content card-bg-1">
                      </div>
                      <div class="innercard-content">
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 mb-4">
                      <div class="card-content card-bg-2">
                      </div>
                      <div class="innercard-content">
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 mb-4">
                      <div class="card-content card-bg-3">
                      </div>
                      <div class="innercard-content">
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 mb-4">
                      <div class="card-content card-bg-4">
                      </div>
                      <div class="innercard-content">
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 mb-4">
                      <div class="card-content card-bg-5">
                      </div>
                      <div class="innercard-content">
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 mb-4">
                      <div class="card-content card-bg-6">
                      </div>
                      <div class="innercard-content">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-admin>
</template>

<script>
import axios from 'axios';
import LayoutAdmin from '../LayoutAdmin.vue';

export default {
  name: 'DashboardPage',
  components: {
    LayoutAdmin,
  },
  data() {
    return {
      user: {},
    };
  },
  created() {
    this.getUser();
    if (localStorage.getItem('token') == "" || localStorage.getItem('token') == null) {
      this.$router.push('/')
    } else {
      this.getUser();
    }

  },
  methods: {
    getUser() {
      axios.get('/api/user', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .then((r) => {
          this.user = r.data;
          return r
        })
        .catch((e) => {
          return e
        });
    },
    logoutAction() {
      axios.post('/logout', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .then((r) => {
          localStorage.setItem('token', "")
          this.$router.push('/')
          return r
        })
        .catch((e) => {
          localStorage.setItem('token', "")
          this.$router.push('/')
          return e
        });
    },

    MUserAction() {

    }

  },
};
</script>