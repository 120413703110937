<template>
    <layout-div>
        <div class="loginpage">
            <div class="imgbglogin"></div>
            <div class="row justify-content-md-center mt-5">
                <div class="col-3">
                    <div class="card">
                        <div class="card-body card-login">
                            <div class="card-title mb-4">
                                <img src="@/assets/img/bappenas.png" alt="Logo Bappenas" style="width: 100%;">
                            </div>
                            <form>
                                <p v-if="Object.keys(validationErrors).length != 0" class='text-center '><small
                                        class='text-danger'>Incorrect Email or Password</small></p>
                                <div class="mb-3">
                                    <label htmlFor="email" class="form-label">
                                        Email address
                                    </label>
                                    <input v-model="email" type="email" class="form-control" id="email" name="email"
                                        placeholder="Email" />
                                </div>
                                <div class="mb-3">
                                    <label htmlFor="password" class="form-label">Password
                                    </label>
                                    <input v-model="password" type="password" class="form-control" id="password"
                                        placeholder="Password" name="password" />
                                </div>
                                <div class="d-grid gap-2 text-left mt30">
                                    <button :disabled="isSubmitting" @click="loginAction()" type="button"
                                        class="btn btn-login">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';

export default {
    name: 'LoginPage',
    components: {
        LayoutDiv,
    },
    data() {
        return {
            email: '',
            password: '',
            validationErrors: {},
            isSubmitting: false,
        };
    },
    created() {
        if (localStorage.getItem('token') != "" && localStorage.getItem('token') != null) {
            this.$router.push('/dashboard')
        }
    },
    methods: {
        async loginAction() {
            this.isSubmitting = true
            let payload = {
                email: this.email,
                password: this.password,
                remember: false,
            }

            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/login', payload, {
                withCredentials: true,
                withXSRFToken: true
            })
                .then(response => {
                    localStorage.setItem('token', response.data.token)
                    this.$router.push('/dashboard')
                    return response
                })
                .catch(error => {
                    this.isSubmitting = false
                    if (error.response.data.errors != undefined) {
                        this.validationErrors = error.response.data.errors
                    }
                    if (error.response.data.error != undefined) {
                        this.validationErrors = error.response.data.error
                    }
                    return error
                });
        }
    },
};
</script>