<template>
    <layout-admin>
        <div class="row justify-content-md-center">
            <div class="col-12">
                <div class="content">
                    <div class="container-white">
                        <div class="body-content">
                            <h2>Manajemen Users</h2>
                            <hr /><br />
                            <div v-if="!showForm">
                                <a class="btn btn-sm btn-primary" href="/user?id=0" style="float: right;">Tambah
                                    Data</a>
                                <br /><br />
                                <DataTable :columns="columns" :data="data" class="table table-hover table-striped" />
                            </div>
                            <div v-if="showForm">
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.name" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.email" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Password</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.password" type="password" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Confirm Password</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.password_confirmation" type="password"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>User Group</label>
                                    </div>
                                    <div class="col-6">
                                        <table class="table table-hover table-striped">
                                            <tr>
                                                <th width="10px"></th>
                                                <th>Name</th>
                                            </tr>
                                            <tr v-for="item in dataTeam" :key="item.id">
                                                <td><input type="checkbox" :value=item.id v-model="form.team_id" /></td>
                                                <td>{{ item.name }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-6">
                                        <button class="btn btn-sm btn-primary" @click="Create()">Simpan</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-admin>
</template>
<script setup>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';

DataTable.use(DataTablesCore);

</script>
<script>

import axios from 'axios';
import LayoutAdmin from '../LayoutAdmin.vue';
import Swal from 'sweetalert2';

export default {
    name: 'UserPage',
    components: {
        LayoutAdmin,
    },
    data() {
        return {
            form: {
                id: 0,
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                team_id: []
            },
            curID: 0,
            user: {},
            data: [],
            dataTeam: [],
            showForm: false,
            columns: [
                { data: 'name', title: 'Name', sort: true },
                { data: 'email', title: 'Email' },
                {
                    data: 'team_user', title: 'Group',
                    render: function (data) {
                        var string = ""
                        data.forEach(element => {
                            string += element.team.name + "<br/>";
                        });
                        return string;
                    }
                },
                {
                    data: 'id',
                    sortable: false,
                    render: function (o) {
                        return '<a href="/user?id=' + o + '" class="btn btn-sm btn-primary">' + 'Edit' + '</a>' + '  ' + '<a href="/user?delete=true&id=' + o + '"  class="btn btn-sm btn-danger">' + 'Hapus' + '</a>';
                    }
                }
            ]
        };
    },
    created() {
        this.curID = this.$route.query.id
        if (this.$route.query.id) {

            if (this.$route.query.delete) {
                this.Delete(this.curID)
            } else {
                this.getForm(this.curID)
            }
        }
        this.getData();
    },
    methods: {
        async getData() {
            Swal.showLoading();
            await axios.get('/sanctum/csrf-cookie')
            await axios.get('/api/user')
                .then((r) => {
                    this.data = r.data.data;
                    Swal.close();
                })
                .catch(() => {
                    Swal.close();
                });
        },
        async getForm(id) {
            Swal.showLoading();
            this.showForm = true
            await axios.get('/api/team')
                .then((r) => {
                    this.dataTeam = r.data.data;
                    return r
                })
                .catch((e) => {
                    return e
                });

            await axios.get('/api/user/' + id)
                .then((r) => {
                    this.form.id = r.data.id;
                    this.form.name = r.data.name;
                    this.form.email = r.data.email;
                    this.form.password = r.data.password;
                    this.form.password_confirmation = r.data.password_confirmation;
                    r.data.team_user.forEach(e => {
                        this.form.team_id.push(e.team_id)
                    });
                    Swal.close();
                })
                .catch(() => {
                    Swal.close();
                });
        },
        async Create() {
            Swal.loading();
            if (this.form.id > 0) {
                await axios.get('/sanctum/csrf-cookie')
                await axios.put('/api/user', this.form)
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(val => {
                            val
                            location.href = "/user";
                        })
                    })
                    .catch(() => {
                        Swal.close();
                    });
            } else {
                await axios.get('/sanctum/csrf-cookie')
                await axios.post('/api/user', this.form)
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(val => {
                            val
                            location.href = "/user";
                        })
                    })
                    .catch(() => {
                        Swal.close();
                    });
            }
        },
        async Delete(ids) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.get('/sanctum/csrf-cookie')
                    await axios.delete('/api/user?id=' + ids)
                        .then((r) => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            }).then(val => {
                                val
                                location.href = "/team";
                            })
                            return r
                        })
                        .catch((e) => {
                            return e
                        });

                }
            });
        },
        logoutAction() {
            axios.post('/logout', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then((r) => {
                    localStorage.setItem('token', "")
                    this.$router.push('/')
                    return r
                })
                .catch((e) => {
                    return e
                });
        },
    }
}
</script>
