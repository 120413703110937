<template>
    <layout-admin>
        <div class="row justify-content-md-center">
            <div class="col-12">
                <div class="content">
                    <div class="container-white">
                        <div class="body-content">
                            <h2>Menus</h2>
                            <hr /><br />
                            <div v-if="!showForm"  style="overflow-y: auto;height: 500px;">
                                <a class="btn btn-sm btn-primary" href="/menu?id=0" style="float: right;">Tambah
                                    Data</a>
                                <br /><br />
                                <DataTable :columns="columns" :data="data" class="table table-hover table-striped" />
                            </div>
                            <div v-if="showForm">
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.label" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Url/Controller</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.name" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Order</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.ordered" type="number" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Parent Menu</label>
                                    </div>
                                    <div class="col-6">
                                        <select v-model="form.parent_id" class="form-control">
                                            <option v-for="item in data" :key="item.id" :value=item.id>{{ item.label }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Description</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.description" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-6">
                                        <a href="/menu" class="btn btn-sm btn-danger"
                                            style="margin-right: 10px;">Cancel</a>
                                        <button class="btn btn-sm btn-primary" @click="CreateUpdate()">Simpan</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-admin>
</template>
<script setup>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';

DataTable.use(DataTablesCore);

</script>
<script>

import axios from 'axios';
import LayoutAdmin from '../LayoutAdmin.vue';
import Swal from 'sweetalert2';

export default {
    name: 'MenuPage',
    components: {
        LayoutAdmin,
    },
    data() {
        return {
            form: {
                id: "",
                name: "",
                label: "",
                description: "",
                ordered: 0,
                parent_id: null
            },
            curID: 0,
            user: {},
            data: [],
            dataTeam: [],
            showForm: false,
            columns: [
                { data: 'label', title: 'Name', sort: true },
                { data: 'name', title: 'URL/Controller', sort: true },
                { data: 'ordered', title: 'Order', sort: true },
                { data: 'parent.name', title: 'Parent Menu', sort: true },
                { data: 'description', title: 'Description', sort: true },
                {
                    data: 'id',
                    sortable: false,
                    render: function (o) {
                        return '<a href="/menu?id=' + o + '" class="btn btn-sm btn-primary">' + 'Edit' + '</a>' + '  ' + '<a href="/menu?delete=true&id=' + o + '"  class="btn btn-sm btn-danger">' + 'Hapus' + '</a>';
                    }
                }
            ]
        };
    },
    created() {
        this.curID = this.$route.query.id
        if (this.$route.query.id) {

            if (this.$route.query.delete) {
                this.Delete(this.curID)
            } else {
                this.getForm(this.curID)
            }
        }
        this.getData();
    },
    methods: {
        async getData() {
            await axios.get('/sanctum/csrf-cookie')
            await axios.get('/api/menu')
                .then((r) => {
                    this.data = r.data.data;
                    return r
                })
                .catch((e) => {
                    return e
                });
        },
        async getForm(id) {
            this.showForm = true
            await axios.get('/sanctum/csrf-cookie')
            await axios.get('/api/menu/' + id)
                .then((r) => {
                    this.form = r.data;
                    console.log()
                    return r
                })
                .catch((e) => {
                    return e
                });
        },
        async CreateUpdate() {
            if (this.form.id > 0) {
                await axios.get('/sanctum/csrf-cookie')
                await axios.put('/api/menu', this.form)
                    .then((r) => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(val => {
                            val
                            location.href = "/menu";
                        })
                        return r
                    })
                    .catch((e) => {
                        return e
                    });
            } else {
                await axios.get('/sanctum/csrf-cookie')
                await axios.post('/api/menu', this.form)
                    .then((r) => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(val => {
                            val
                            location.href = "/menu";
                        })
                        return r
                    })
                    .catch((e) => {
                        return e
                    });
            }
        },
        async Delete(ids) {

            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.get('/sanctum/csrf-cookie')
                    await axios.delete('/api/menu?id=' + ids)
                        .then((r) => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            }).then(val => {
                                val
                                location.href = "/menu";
                            })
                            return r
                        })
                        .catch((e) => {
                            return e
                        });

                }
            });
        },
        logoutAction() {
            axios.post('/logout', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then((r) => {
                    localStorage.setItem('token', "")
                    this.$router.push('/')
                    return r
                })
                .catch((e) => {
                    localStorage.setItem('token', "")
                    this.$router.push('/')

                    return e
                });
        },
    }
}
</script>
