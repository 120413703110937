<template>
    <layout-admin>
        <div class="row justify-content-md-center">
            <div class="col-12">
                <div class="content">
                    <div class="container-white">
                        <div class="body-content">
                            <h2>Manajemen Groups</h2>
                            <hr /><br />
                            <div v-if="!showForm && !showAccess">
                                <a class="btn btn-sm btn-primary" href="/team?id=0" style="float: right;">Tambah
                                    Data</a>
                                <br /><br />
                                <DataTable :columns="columns" :data="data" class="table table-hover table-striped" />
                            </div>
                            <div v-if="showAccess">
                                <div class="row">
                                    <div class="col-6">
                                        <table class="table table-hover table-striped">
                                            <tr>
                                                <th>Name(URI)</th>
                                                <th width="10px">Can View</th>
                                                <th width="10px">Can Add</th>
                                                <th width="10px">Can Edit</th>
                                                <th width="10px">Can Delete</th>
                                            </tr>
                                            <tr v-for="item in dataMenu" :key="item.id">
                                                <td>{{ item.label }} ({{ item.name }})</td>

                                                <td><input type="checkbox" v-model="item.read" :name=item.id /></td>

                                                <td><input type="checkbox" v-model="item.create" :name=item.id /></td>

                                                <td><input type="checkbox" v-model="item.update" :name=item.id /></td>

                                                <td><input type="checkbox" v-model="item.delete" :name=item.id /></td>
                                            </tr>
                                        </table>
                                        <div class="row form-group">
                                            <div class="col-6">
                                                <a href="/team" class="btn btn-sm btn-danger"
                                                    style="margin-right: 10px;">Cancel</a>
                                                <button class="btn btn-sm btn-primary"
                                                    @click="CreateAccess()">Simpan</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showForm">
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="form.name" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-2">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-6">
                                        <select class="form-control" v-model="status">
                                            <option value="active">active</option>
                                            <option value="inactive">inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-6">
                                        <a href="/team" class="btn btn-sm btn-danger"
                                            style="margin-right: 10px;">Cancel</a>
                                        <button class="btn btn-sm btn-primary" @click="Create()">Simpan</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-admin>
</template>
<script setup>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';

DataTable.use(DataTablesCore);

</script>
<script>

import axios from 'axios';
import LayoutAdmin from '../LayoutAdmin.vue';
import Swal from 'sweetalert2';

export default {
    name: 'TeamPage',
    components: {
        LayoutAdmin,
    },
    data() {
        return {
            form: {
                id: 0,
                name: "",
                status: "active",
            },
            formAccess: {
                accessid: []
            },
            curID: 0,
            user: {},
            data: [],
            dataTeam: [],
            dataMenu: [],
            showForm: false,
            showAccess: false,
            columns: [
                { data: 'name', title: 'Name', sort: true },
                { data: 'status', title: 'Status' },
                {
                    data: 'id',
                    sortable: false,
                    render: function (o) {
                        return '<a href="/team?access=true&id=' + o + '" class="btn btn-sm btn-success">' + 'Hak Akses' + '</a>' + '  ' + '<a href="/team?id=' + o + '" class="btn btn-sm btn-primary">' + 'Edit' + '</a>' + '  ' + '<a href="/team?delete=true&id=' + o + '"  class="btn btn-sm btn-danger">' + 'Hapus' + '</a>';
                    }
                }
            ]
        };
    },
    created() {
        this.curID = this.$route.query.id
        if (this.$route.query.id) {
            if (this.$route.query.delete) {
                this.Delete(this.curID)
            } else if (this.$route.query.access) {
                this.getAccess(this.curID)
                this.getMenu()
            } else {
                this.getForm(this.curID)
            }
        }
        this.getData();
    },
    methods: {
        async getData() {
            Swal.showLoading();
            await axios.get('/sanctum/csrf-cookie')
            await axios.get('/api/team')
                .then((r) => {
                    this.data = r.data.data;
                    Swal.close();
                })
                .catch(() => {
                    Swal.close();
                });
        },
        async getForm(id) {
            Swal.showLoading();
            this.showForm = true
            await axios.get('/sanctum/csrf-cookie')
            await axios.get('/api/team/' + id)
                .then((r) => {
                    this.form = r.data;
                    Swal.close();
                })
                .catch(() => {
                    Swal.close();
                });
        },
        async getAccess() {
            this.showAccess = true
        },
        async Create() {
            Swal.showLoading();
            if (this.form.id > 0) {
                await axios.get('/sanctum/csrf-cookie')
                await axios.put('/api/team', this.form)
                    .then(() => {
                        //this.data = r.data.data;
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(val => {
                            val
                            location.href = "/team";
                        })
                    })
                    .catch((e) => {
                        Swal.close();
                        Swal.fire({
                            title: e.response.data.message,
                            icon: "danger"
                        });
                        return e
                    });
            } else {
                await axios.get('/sanctum/csrf-cookie')
                await axios.post('/api/team', this.form)
                    .then((r) => {
                        //this.data = r.data.data;
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(val => {
                            val
                            location.href = "/team";
                        })
                        return r
                    })
                    .catch((e) => {
                        Swal.close();
                        Swal.fire({
                            title: e.response.data.message,
                            icon: "danger"
                        });
                        return e
                    });
            }
        },
        async Delete(ids) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.get('/sanctum/csrf-cookie')
                    await axios.delete('/api/team?id=' + ids)
                        .then((r) => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            }).then(val => {
                                val
                                location.href = "/team";
                            })
                            return r
                        })
                        .catch((e) => {
                            return e
                        });

                }
            });
        },
        logoutAction() {
            axios.post('/logout', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .then((r) => {
                    localStorage.setItem('token', "")
                    this.$router.push('/')
                    return r
                })
                .catch((e) => {
                    return e
                });
        },

        async getMenu() {
            Swal.showLoading();
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/api/access/team', { team_id: this.$route.query.id })
                .then((r) => {
                    axios.get('/api/menu')
                        .then((rm) => {
                            this.dataMenu = rm.data.data;

                            this.dataMenu.forEach((e, i) => {
                                var dataAccess = r.data.filter(x => x.menu_id == e.id)
                                if (dataAccess.length > 0) {
                                    this.dataMenu[i]['idAccess'] = dataAccess[0].id

                                    if (dataAccess[0].abilities.filter(x => x == "create")[0]) {
                                        this.dataMenu[i]['create'] = true
                                    } else {
                                        this.dataMenu[i]['create'] = false
                                    }
                                    if (dataAccess[0].abilities.filter(x => x == "read")[0]) {
                                        this.dataMenu[i]['read'] = true
                                    } else {
                                        this.dataMenu[i]['read'] = false
                                    }
                                    if (dataAccess[0].abilities.filter(x => x == "update")[0]) {
                                        this.dataMenu[i]['update'] = true
                                    } else {
                                        this.dataMenu[i]['update'] = false
                                    }
                                    if (dataAccess[0].abilities.filter(x => x == "delete")[0]) {
                                        this.dataMenu[i]['delete'] = true
                                    } else {
                                        this.dataMenu[i]['delete'] = false
                                    }
                                }
                            });
                            Swal.close();

                        })
                        .catch(() => {
                            Swal.close();
                        });
                    return r
                })
                .catch((e) => {
                    Swal.close();

                    axios.get('/api/menu')
                        .then((rm) => {
                            this.dataMenu = rm.data.data;
                        })
                        .catch((e) => {
                            return e
                        });
                    return e
                });
        },
        async CreateAccess() {
            var i = 1
            this.dataMenu.forEach(async e => {
                var ArrayAbilities = []
                if (e.create) {
                    ArrayAbilities.push("create")
                }
                if (e.read) {
                    ArrayAbilities.push("read")
                }
                if (e.update) {
                    ArrayAbilities.push("update")
                }
                if (e.delete) {
                    ArrayAbilities.push("delete")
                }

                var AcccesData = {
                    "id": e.idAccess,
                    "menu_id": e.id,
                    "team_id": this.$route.query.id,
                    "abilities": ArrayAbilities
                }
                Swal.showLoading();

                if (e.idAccess > 0) {
                    await axios.get('/sanctum/csrf-cookie')
                    await axios.put('/api/access', AcccesData)
                        .then(() => {

                        })
                        .catch(() => {

                        });
                } else {
                    await axios.get('/sanctum/csrf-cookie')
                    await axios.post('/api/access', AcccesData)
                        .then(() => {
                        })
                        .catch(() => {
                        });
                }
                if (i == this.dataMenu.length) {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(val => {
                        val
                        location.href = "/team";
                    })
                }
                i = i + 1
            });
        }
    }
}
</script>
