import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/pages/LoginPage';
import RegisterPage from './components/pages/RegisterPage';
import DashboardPage from './components/pages/DashboardPage';
import UserPage from './components/pages/UserPage.vue';
import TeamPage from './components/pages/TeamPage.vue';
import MenuPage from './components/pages/MenuPage.vue';

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withXSRFToken = true
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {    
  config.headers['X-Requested-With'] = 'XMLHttpRequest';    
   
  return config;
});

  
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: LoginPage },
    { path: '/register', component: RegisterPage },
    { path: '/dashboard', component: DashboardPage },
    { path: '/user', component: UserPage },
    { path: '/team', component: TeamPage },
    { path: '/menu', component: MenuPage },
  ],
});
  
createApp(App).use(router).mount('#app');