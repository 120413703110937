<template>
  <div class="container bg-general">

    <nav class="navbar navbar-expand-lg navbar-light bg-primary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"></a>
        <div class="d-flex">
          <ul class="navbar-nav">

            <li class="nav-item">
              <a class="nav-link " style="color: #fff;" aria-current="page" href="/">Dashboard</a>
            </li>
            <li class="nav-item" v-for="(item, index) in dataMenu" :key="item.id">
              <a class="nav-link " style="color: #fff;" aria-current="page" :href="index">{{ index }}</a>
            </li>
            <li class="nav-item">
              <a @click="logoutAction()" class="nav-link " style="color: #fff;" aria-current="page"
                href="javascript:void(0);">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <slot></slot>
  </div>
</template>

<script>
import '../assets/custom.css';
import axios from 'axios';

export default {
  name: 'LayoutAdmin',
  data() {
    return {
        dataMenu: [],
      }
  },
  created() {
    this.getMenu()
  },
  methods: {
    async getMenu() {
      await axios.get('/sanctum/csrf-cookie')
      await axios.get('/api/user-session')
        .then((r) => {
          this.dataMenu = r.data.data.menu
          return r
        })
        .catch((e) => {
          return e
        });
    },
    logoutAction() {
      axios.post('/logout')
        .then((r) => {
          localStorage.setItem('token', "")
          this.$router.push('/')
          return r
        })
        .catch((e) => {
          localStorage.setItem('token', "")
          this.$router.push('/')

          return e
        });
    },
  }
};
</script>