<template>
    <div class="container bg-general">
      <slot></slot>
    </div>
  </template>
    
  <script>
  import '../assets/custom.css';

  export default {
      name: 'LayoutDiv',
  };
  </script>